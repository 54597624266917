export const SEND_PAYMENT_METHOD_KEY = 'payment_method' as const;
export const SEND_FROM_AMOUNT_KEY = 'from_amount' as const;
export const SEND_FROM_CURRENCY_KEY = 'from_currency' as const;
export const SEND_TO_AMOUNT_KEY = 'to_amount' as const;
export const SEND_TO_CURRENCY_KEY = 'to_currency' as const;
// LOCAL BANK TRANSFER
export const SEND_BANK_TRANSFER_ACCOUNT_NUMBER_KEY = 'account_number' as const;
export const SEND_BANK_TRANSFER_BANK_NAME_KEY = 'bank_name' as const;
export const SEND_BANK_TRANSFER_ACCOUNT_NAME_KEY = 'account_name' as const;

export const SEND_BANK_TRANSFER_BANK_CODE_KEY = 'bank_code' as const;
export const SEND_IS_SAVE_TO_BENEFICIARY_KEY = 'is_save_to_beneficiary' as const;
// SEND DEFI
export const SEND_DEFI_ASSET_NAME_KEY = 'assets' as const;
export const SEND_DEFI_NETWORK_NAME_KEY = 'networks' as const;
export const SEND_DEFI_ADDRESS_NAME_KEY = 'address' as const;
// INTERNATIONAL BANK TRANSFER
export const SEND_BANK_TRANSFER_BENEFICIARY_KEY = 'beneficiary_name';
export const SEND_BANK_TRANSFER_BSB_KEY = 'bsb_code';
// PAYPAL TRANSFER
export const SEND_PAYPAL_TRANSFER_KEY = 'paypal_id';
// MOBILE MONEY
export const SEND_MOBILE_PHONE_NUMBER_KEY = 'phone_number';
// CASH APP
export const SEND_CASH_APP_ID_KEY = 'cashapp_id';
// ZELLE
export const SEND_ZELLE_ID_KEY = 'zelle_id';

export const BENEFICIARY_PAYMENT_METHOD_KEY = 'payment_method' as const;
export const BENEFICIARY_CURRENCY_KEY = 'currency' as const;
