import { AnimatePresence, motion } from 'framer-motion';
import { cn } from 'lib/utils';
import { useId, useMemo } from 'react';
import ReactSelect, { MultiValue, OptionProps, SingleValue } from 'react-select';

import InputLabel from './inputLabel';
import { SelectOption, SelectProps, TertiaryOption } from './types';

const Select = ({
  label,
  className,
  labelClassName,
  id,
  required,
  setTouched,
  setValue,
  placeholder,
  value,
  options,
  disabled,
  name,
  error,
  touched,
  isLoading,
  multiple,
  eagerError,
  variant = 'primary',
  tertiaryOptions,
  controlClassName,
  fieldError,
  setFieldTouched,
  submitError,
}: SelectProps) => {
  const showErrorMessage =
    submitError ||
    (touched &&
      ((fieldError !== undefined && value?.length === 0) ||
        error ||
        eagerError ||
        (!value && !multiple) ||
        multiple));

  const handleChange = async (
    option:
      | SingleValue<SelectOption>
      | MultiValue<SelectOption>
      | SingleValue<TertiaryOption>
      | null
  ) => {
    // logic here
    await setTouched?.(true, true);
    if (setFieldTouched && value && value?.length > 0) setFieldTouched(id, false, true);
    if (setValue && option && 'value' in option && !multiple) {
      await setValue(option.value, true);
      return;
    }

    if (setValue && option && Array.isArray(option) && Array.isArray(value) && multiple) {
      const newValue = option.map((item) => item.value);

      await setValue(newValue, true);
    }
  };

  const handleBlur = async () => {
    if (setTouched) {
      await setTouched(true, true);
    }
    if (setFieldTouched) await setFieldTouched(id, true, true);
  };

  const selectedValue:
    | SingleValue<SelectOption>
    | MultiValue<SelectOption>
    | SingleValue<TertiaryOption> = useMemo(() => {
    if (!multiple) {
      if (variant !== 'tertiary') {
        if (!value || !options) {
          return {
            value: '',
            label: placeholder ? placeholder : 'Select Option',
          };
        }
        const findSelected = options.find((option) => option.value === value);

        if (!findSelected) {
          return {
            value: '',
            label: placeholder ? placeholder : 'Select Option',
          };
        }
        return findSelected;
      } else {
        if (!value || !tertiaryOptions) {
          return {
            value: '',
            label: 'Select Option',
            img: 'Select Option',
          };
        }
        const findSelected = tertiaryOptions?.find((option) => option.value === value);

        if (!findSelected) {
          return {
            value: '',
            label: placeholder ? placeholder : 'Select Option',
          };
        }
        return findSelected;
      }
    }

    if (multiple && Array.isArray(value) && options) {
      const valuesSet = new Set(value);
      return options.filter((option) => valuesSet.has(option.value));
    }

    if (variant === 'tertiary') {
      return [] as TertiaryOption[];
    } else {
      return [] as SelectOption[];
    }
  }, [options, value, placeholder, multiple, variant, tertiaryOptions]);

  const instanceId = useId();

  const customSingleValue = ({ data }: { data: TertiaryOption }) => (
    <div className="flex items-center">
      {value && <img src={data.img} alt={data.label} className="w-5 h-5 mr-2" />}
      {data.label}
    </div>
  );

  const customOption = (props: OptionProps<TertiaryOption>) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className="flex items-center p-2 hover:bg-platnova-purple/20 cursor-pointer"
      >
        <img src={data.img} alt={data.label} className="w-5 h-5 mr-2" />
        {data.label}
      </div>
    );
  };

  const redBorder = showErrorMessage ? 'border-[1px] border-error-primary' : '';

  return (
    <div className={cn('', className)}>
      <div className="flex flex-col gap-2">
        {label && !!label.length && (
          <div className="flex flex-col items-start justify-center gap-1">
            {label && !!label.length && (
              <InputLabel
                className={cn('text-xs md:text-sm font-normal', labelClassName)}
                id={id}
                label={label}
              />
            )}
          </div>
        )}
        {(variant === 'primary' || variant === 'secondary') && (
          <ReactSelect
            placeholder={`${placeholder ? placeholder : 'Select Option'}`}
            value={selectedValue}
            options={options}
            onBlur={handleBlur}
            onFocus={handleBlur}
            id={id}
            onChange={handleChange}
            isDisabled={disabled || isLoading}
            required={required}
            name={name}
            captureMenuScroll={true}
            instanceId={instanceId}
            isLoading={isLoading}
            isMulti={multiple}
            classNames={{
              option: (state) =>
                cn(
                  'hover:bg-platnova-secondary p-2 bg-white capitalize text-xs md:text-base',
                  [state.isSelected && 'font-bold'],
                  [state.isFocused && 'bg-primary bg-opacity-10 border']
                ),
              control: () =>
                `${variant === 'primary' && 'py-3.5  text-xs md:text-base bg-primary-grey'} ${
                  variant === 'secondary' && 'py-2  text-xs md:text-base'
                } w-full rounded-lg px-2 py-2 capitalize outline-none transition-all duration-100 ease-in placeholder:text-base md:px-4 xl:placeholder:text-base flex react-select ${redBorder}`,
              placeholder: () => 'text-secondary-grey text-xs md:text-base',
              noOptionsMessage: () => ' text-xs md:text-base',
              dropdownIndicator: () => cn('text-primary-black/80 p-0'),
              input: () => cn('p-0'),
              multiValue: () => 'bg-primary/50 text-white flex rounded-sm px-1',
              multiValueLabel: () => 'text-[0.85em]',
              multiValueRemove: () => 'hover:bg-primary-red',
              valueContainer: () => cn([multiple && 'flex flex-wrap gap-1']),
            }}
            styles={{
              control: () => {
                return {};
              },
              option: () => ({}),
              valueContainer: (baseStyles) => ({
                ...baseStyles,
                padding: 0,
                margin: 0,
                gap: '0.25rem',
              }),
              dropdownIndicator: () => ({}),
              input: (baseStyles) => ({ ...baseStyles, margin: 0, padding: 0 }),
              indicatorSeparator: () => ({}),
              placeholder: (base) => ({ ...base }),
              menuList: (base) => ({ ...base, maxHeight: '10rem' }),
              multiValue: () => ({}),
              multiValueLabel: () => ({}),
              multiValueRemove: () => ({}),
            }}
          />
        )}
        {variant === 'tertiary' && tertiaryOptions && (
          <ReactSelect
            placeholder={`${placeholder ? placeholder : 'Select Option'}`}
            value={selectedValue}
            options={tertiaryOptions}
            onBlur={handleBlur}
            onFocus={handleBlur}
            id={id}
            onChange={handleChange}
            isDisabled={disabled || isLoading}
            required={required}
            name={name}
            captureMenuScroll={true}
            instanceId={instanceId}
            isLoading={isLoading}
            isMulti={multiple}
            classNames={{
              control: () =>
                cn(
                  'py-3.5 text-xs border border-transparent focus-within:border-2 focus-within:outline-none focus-within:border-platnova-purple md:text-base bg-primary-grey w-full rounded-lg px-2 capitalize outline-none transition-all duration-100 ease-in placeholder:text-xs px-4 xl:placeholder:text-base flex',
                  controlClassName
                ),
              placeholder: () => 'text-secondary-grey text-xs md:text-base',
              noOptionsMessage: () => ' text-xs md:text-base',
              dropdownIndicator: () => cn('text-primary-black/80 p-0'),
              input: () => cn('p-0 h-0'),
              multiValue: () => 'bg-primary/50 text-white flex rounded-sm px-1',
              multiValueLabel: () => 'text-[0.85em]',
              multiValueRemove: () => 'hover:bg-primary-red',
              valueContainer: () => cn([multiple && 'flex flex-wrap gap-1']),
            }}
            styles={{
              control: () => {
                return {};
              },
              option: () => ({}),
              valueContainer: (baseStyles) => ({
                ...baseStyles,
                padding: 0,
                margin: 0,
                gap: '0.25rem',
              }),
              dropdownIndicator: () => ({}),
              input: (baseStyles) => ({ ...baseStyles, margin: 0, padding: 0 }),
              indicatorSeparator: () => ({}),
              placeholder: (base) => ({ ...base }),
              menuList: (base) => ({ ...base, maxHeight: '10rem' }),
              multiValue: () => ({}),
              multiValueLabel: () => ({}),
              multiValueRemove: () => ({}),
            }}
            components={{ SingleValue: customSingleValue, Option: customOption }}
          />
        )}
        {variant === 'multi' &&
          options &&
          options?.map((option) => (
            <button
              onClick={(e) => {
                e.preventDefault();
                handleChange(option);
              }}
              key={option.value}
              className="w-full flex justify-between bg-secondary-grey px-4 py-5 rounded-[10px] cursor-pointer"
            >
              {option.label}
            </button>
          ))}
      </div>
      <AnimatePresence>
        <div className="h-5">
          {showErrorMessage && (
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
              transition={{ ease: 'easeOut', duration: 0.5 }}
              className="pl-1 text-xs md:text-sm font-semibold text-error-primary"
            >
              {fieldError || error}
            </motion.div>
          )}
        </div>
      </AnimatePresence>
    </div>
  );
};

export default Select;
