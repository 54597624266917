import { HTMLAttributes } from 'react';

export const ArrowRightIcon = ({
  width,
  height,
  ...rest
}: { width: number; height: number } & HTMLAttributes<SVGElement>) => {
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M13.334 22.6615L20.0007 15.9948L13.334 9.32812"
        stroke="black"
        strokeOpacity="0.7"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const ArrowDownIcon = ({
  width,
  height,
  ...rest
}: { width: number; height: number } & HTMLAttributes<SVGElement>) => {
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path d="M9.33398 13.3281L16.0007 19.9948L22.6673 13.3281H9.33398Z" fill="black" />
    </svg>
  );
};
