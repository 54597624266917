import { useQuery } from '@tanstack/react-query';

import { getConfigurations, getCountryStates, getTransactionLimitsConfiguration } from './function';
import {
  GET_CONFIGURATIONS_KEY,
  GET_COUNTRY_STATES,
  GET_TRANSACTION_LIMITS_CONFIGURATION_KEY,
} from './keys';

export const useGetConfigurations = () => {
  const { data, isPending, isError } = useQuery({
    queryKey: [GET_CONFIGURATIONS_KEY],
    queryFn: getConfigurations,
  });

  return {
    data,
    isPending,
    isError,
  };
};

export const useGetCountryStates = () => {
  const { data, isPending, isError } = useQuery({
    queryKey: [GET_COUNTRY_STATES],
    queryFn: getCountryStates,
  });

  return {
    data,
    isPending,
    isError,
  };
};

export const TransactionLimitsSortArray = [
  'deposit',
  'send_payment',
  'convert_payment',
  'create_card',
  'credit_card',
  'withdraw_card',
  'tuition_payment',
  'vault',
  'buy_crypto',
  'sell_crypto',
  'send_crypto',
  'receive_crypto',
  'swap_crypto',
  'lock_crypto',
];
export const useGetTransactionLimitsConfigurations = () => {
  const { data, isPending, isError } = useQuery({
    queryKey: [GET_TRANSACTION_LIMITS_CONFIGURATION_KEY],
    queryFn: getTransactionLimitsConfiguration,
  });

  return {
    data,
    isPending,
    isError,
  };
};
