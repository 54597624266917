import InputSearch from 'components/Inputs/inputSearch';
import useDisclosure from 'hooks/useDisclosure';
import { ChevronLeft } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetGiftcards } from 'services/refill';
import { Airtime } from 'services/refill/constants';
import { GiftCard } from 'services/refill/types';
import { useAppDispatch } from 'store';
import { setActiveRefill } from 'store/slices/refill/refillSlice';

import PurchaseModal from './components/purchaseModal';
import SubRouteLayout from '../components/subRouteLayout';

const Giftcards = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [giftCards, setGiftCards] = useState<GiftCard[]>([]);
  // const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get('search') || '';

  const { isOpen, close, open } = useDisclosure();
  const dispatch = useAppDispatch();

  const customOpen = (active: GiftCard | Airtime) => {
    dispatch(setActiveRefill(active));
    open();
  };

  const observerRef = useRef(null);

  const { data: giftCardsResponse, isPending: isLoadingGiftcards } = useGetGiftcards({
    page: page,
    name: searchValue,
  });

  useEffect(() => {
    setPage(0);
    setGiftCards([]);
  }, [searchValue]);

  useEffect(() => {
    if (giftCardsResponse?.data.items) {
      if (page === 0) {
        setGiftCards(giftCardsResponse.data.items);
      } else {
        setGiftCards((prev) => [...prev, ...giftCardsResponse.data.items]);
      }
    }
  }, [giftCardsResponse, page]);

  useEffect(() => {
    if (giftCardsResponse?.data.last) {
      return;
    }

    const currentObserverRef = observerRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoadingGiftcards) {
          // setIsFetchingMore(true);
          setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1.0 }
    );

    if (currentObserverRef) {
      observer.observe(currentObserverRef);
    }

    return () => {
      if (currentObserverRef) observer.unobserve(currentObserverRef);
    };
  }, [giftCardsResponse, isLoadingGiftcards]);

  // useEffect(() => {
  //   if (!isLoadingGiftcards) {
  //     setIsFetchingMore(false);
  //   }
  // }, [isLoadingGiftcards]);

  return (
    <SubRouteLayout header="Giftcards">
      <div className="pb-20 xl:-ml-[4.688rem]">
        <div className="xl:flex items-start xl:space-x-11">
          <button
            onClick={() => navigate(-1)}
            className="flex space-x-1.5 xl:space-x-3 items-center"
          >
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </button>

          <div className="space-y-10 w-[37.5rem]">
            <div className="space-y-3">
              <p className="text-lg md:text-2xl font-medium capitalize">Select Gift card</p>
              <p className="text-xs md:text-base text-black/70">
                Select one from our range of gift cards.
              </p>

              <InputSearch />

              <div className="grid grid-cols-3 gap-10">
                {!isLoadingGiftcards &&
                  giftCards &&
                  giftCards.map((giftcard, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        customOpen(giftcard);
                      }}
                      className="relative cursor-pointer rounded-lg overflow-hidden w-full aspect-video h-[120px]"
                    >
                      {giftcard.giftcard_meta.countries ? (
                        <img
                          src={giftcard.giftcard_meta.countries[0].logo}
                          alt={giftcard.name}
                          className="absolute inset-0 object-cover w-full h-full"
                        />
                      ) : (
                        <div className="absolute inset-0 bg-grey-text"></div>
                      )}
                      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/50"></div>{' '}
                      {/* Gradient dark overlay */}
                      <p className="absolute bottom-2 left-2 right-2 text-white text-sm md:text-base px-2 whitespace-nowrap overflow-hidden text-ellipsis text-left">
                        {giftcard.name}
                      </p>
                    </button>
                  ))}
              </div>
              {isLoadingGiftcards && (
                <div className="grid grid-cols-3 gap-10">
                  <Skeleton className="h-36" />
                  <Skeleton className="h-36" />
                  <Skeleton className="h-36" />
                  <Skeleton className="h-36" />
                  <Skeleton className="h-36" />
                  <Skeleton className="h-36" />
                  <Skeleton className="h-36" />
                  <Skeleton className="h-36" />
                  <Skeleton className="h-36" />
                  <Skeleton className="h-36" />
                  <Skeleton className="h-36" />
                  <Skeleton className="h-36" />
                  <Skeleton className="h-36" />
                  <Skeleton className="h-36" />
                  <Skeleton className="h-36" />
                  <Skeleton className="h-36" />
                </div>
              )}
            </div>
            <div ref={observerRef} className="h-1"></div>
          </div>
        </div>
      </div>
      <PurchaseModal isOpen={isOpen} close={close} />
    </SubRouteLayout>
  );
};

export default Giftcards;
