import { alpha, styled, Switch } from '@mui/material';
import Button from 'components/Buttons/Button';
import Flag from 'components/flag';
import DynamicInput, { DynamicInputRefProps } from 'components/Inputs/dynamicInput';
import { SEND_DEFI_ASSET_NAME_KEY, SEND_PAYMENT_METHOD_KEY } from 'lib/constants/accounts/send';
import { useGetAllSendPaymentMethods, useGetSendBeneficiaryDynamicInputs } from 'lib/utils';
import { ChevronLeft } from 'lucide-react';
import { useCallback, useLayoutEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { setSaveToBeneficiary, setSendPaymentStep } from 'store/slices/account/accountsSlice';
import {
  ACCOUNTS_SLICE_REDUCER_PATH,
  SAVE_TO_BENEFICIARY,
  SEND_PAYMENT_INPUT_VALUES,
  SendPaymentInputValues,
} from 'store/slices/account/constants';
import { RECIPIENT_CURRENCY_KEY, WALLET_SLICE_REDUCER_PATH } from 'store/slices/wallet/constants';
import useSendAssetForm from 'views/auth/login/hooks/useSendAssetForm';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';
import SubRouteLayout from 'views/dashboard/components/subRouteLayout';

type fieldRefProp = DynamicInputRefProps;

const UserDetails = () => {
  const dispatch = useAppDispatch();
  const { errors, getInputProps, initialValues } = useSendAssetForm();
  const fieldRefs = useRef<Record<string, fieldRefProp>>({});
  const setRefs = (el: fieldRefProp, fieldId: string) => {
    fieldRefs.current[fieldId] = el;
  };

  const { [SEND_PAYMENT_METHOD_KEY]: sendPaymentMethod, [SAVE_TO_BENEFICIARY]: saveToBeneficiary } =
    useAppSelector((state) => state[ACCOUNTS_SLICE_REDUCER_PATH]);

  const paymentMethod = useGetAllSendPaymentMethods()?.find((x) => x.id === sendPaymentMethod);

  const sendPaymentInputValues: SendPaymentInputValues = useAppSelector(
    (state) => state[ACCOUNTS_SLICE_REDUCER_PATH][SEND_PAYMENT_INPUT_VALUES]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSaveToBeneficiary(event.target.checked));
  };

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#7049F7',
      '&:hover': {
        backgroundColor: alpha('#7049F7', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#7049F7',
    },
  }));

  const { [RECIPIENT_CURRENCY_KEY]: recipientCurrency, recipient_country } = useAppSelector(
    (state) => state[WALLET_SLICE_REDUCER_PATH]
  );

  const fields = useGetSendBeneficiaryDynamicInputs(recipientCurrency, sendPaymentMethod);
  const requiredFields = fields?.filter((item) => item.required);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // const visibleFields = fields?.filter((item) => !item.hide_on_submit);
    const allFieldsGotValue = requiredFields?.length === Object.keys(sendPaymentInputValues).length;
    if (allFieldsGotValue && Object.values(sendPaymentInputValues).every((val) => val.length > 0)) {
      dispatch(setSendPaymentStep(3));
    } else {
      // Call error messages in the various rendered fields
      requiredFields?.forEach((field) => {
        const foundRef = fieldRefs.current[field.id];
        if (typeof foundRef !== 'undefined' && foundRef !== null && foundRef)
          foundRef.CallErrorIFUnFilled();
      });
    }
  };
  const getPresentFieldIds = useCallback(() => {
    const existingFieldsArr: Array<string> = [];
    requiredFields?.forEach((field) => {
      existingFieldsArr.push(field.id);
    });
    return existingFieldsArr;
  }, [requiredFields]);
  const ResetOnChangePrevField = (id: string) => {
    getPresentFieldIds().forEach((presentId, index, arr) => {
      if (presentId.includes(id)) {
        if (fieldRefs.current[arr[index + 1]] !== undefined)
          fieldRefs.current[arr[index + 1]].ResetField();
        return;
      }
    });
  };
  const FilterFirstFields = useCallback(
    (id: string) => {
      return !getPresentFieldIds()[0].includes(id);
    },
    [getPresentFieldIds]
  );
  const UnHideNextField = (id: string) => {
    const UnHide = (id: string) => {
      const node = document.getElementById(`${id}-input`);
      const classes = node?.classList;
      classes?.remove('hidden');
    };
    getPresentFieldIds().forEach((presentId, index, arr) => {
      if (presentId.includes(id)) {
        UnHide(arr[index + 1]);
      }
    });
  };

  const ShowNextInput = (id: string) => {
    ResetOnChangePrevField(id);
    UnHideNextField(id);
  };

  useLayoutEffect(() => {
    const HideInputs = () =>
      fields
        ?.filter((field) => FilterFirstFields(field.id))
        .forEach((field) => {
          getPresentFieldIds().forEach((presentId) => {
            if (presentId.includes(field.id)) {
              const foundNode = document.getElementById(`${presentId}-input`);
              foundNode?.classList.add('hidden');
            }
          });
        });
    if (getPresentFieldIds().includes(SEND_DEFI_ASSET_NAME_KEY)) HideInputs();
    getPresentFieldIds().forEach((presentId) => {
      if (fieldRefs.current[presentId] !== undefined) fieldRefs.current[presentId].ResetField(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <div>
      {paymentMethod?.id !== 'tag_transfer' && (
        <SubRouteLayout header={paymentMethod?.name ?? ''}>
          <div className="pb-20 xl:-ml-[4.688rem] w-full max-w-[52rem]">
            <div className="xl:flex items-start xl:space-x-11 w-full">
              <button
                onClick={() => dispatch(setSendPaymentStep(0))}
                className="flex space-x-1.5 xl:space-x-3 items-center"
              >
                <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
                <span className="text-xs xl:text-base">Back</span>
              </button>

              <div className="w-full mt-5 xl:mt-0 space-y-10">
                <div className="flex justify-between items-center w-full">
                  <div>
                    <p className="text-xl xl:text-2xl font-medium">Recipient details</p>
                    <p className="mt-1 xl:mt-2 font-medium text-xs xl:text-lg text-grey-text">
                      Please enter the details of the recipient
                    </p>
                  </div>
                  <Flag code={recipient_country.toUpperCase()} />
                </div>
                <form className="space-y-20" onSubmit={handleSubmit}>
                  <DashboardCardLayout className="xl:w-full">
                    {fields &&
                      fields.map((field) =>
                        field.id !== 'tag_ids' ? (
                          <DynamicInput
                            id={field.id}
                            ref={(el) => setRefs(el as DynamicInputRefProps, field.id)}
                            key={field.id}
                            label={field.label}
                            disabled={field.disabled}
                            hiddenState={field.hidden}
                            maxLength={field.limit}
                            placeholder={field.place_holder}
                            dynamicVariant={
                              field.type === 'list'
                                ? 'select'
                                : field.type === 'phone'
                                ? 'phone'
                                : 'input'
                            }
                            field={field}
                            fieldError={
                              Object.keys(initialValues).includes(field.id)
                                ? errors[field.id as keyof typeof initialValues]
                                : undefined
                            }
                            formikInputProps={getInputProps}
                            GetId={ShowNextInput}
                          />
                        ) : (
                          <DynamicInput
                            id={field.id}
                            ref={(el) => setRefs(el as DynamicInputRefProps, field.id)}
                            key={field.id}
                            label={field.label}
                            disabled={field.disabled}
                            hiddenState={field.hidden}
                            maxLength={field.limit}
                            placeholder={field.place_holder}
                            dynamicVariant="tag_ids"
                            field={field}
                            fieldError={
                              Object.keys(initialValues).includes(field.id)
                                ? errors[field.id as keyof typeof initialValues]
                                : undefined
                            }
                          />
                        )
                      )}

                    <div className="w-full flex justify-between">
                      <p className="text-sm md:text-base">Save as beneficiary</p>
                      <CustomSwitch checked={saveToBeneficiary} onChange={handleChange} />
                    </div>
                  </DashboardCardLayout>
                  <Button variant="purple" type="submit" className="w-full max-w-[52rem]">
                    Proceed
                  </Button>
                  {/* <Input id="search" variant="secondary" /> */}
                </form>
                <div></div>
              </div>
            </div>
          </div>
        </SubRouteLayout>
      )}
      {paymentMethod?.id === 'tag_transfer' && (
        <SubRouteLayout header={paymentMethod?.name ?? ''}>
          <div className="pb-20 xl:-ml-[4.688rem] w-full max-w-[52rem]">
            <div className="xl:flex items-start xl:space-x-11 w-full">
              <button
                onClick={() => dispatch(setSendPaymentStep(1))}
                className="flex space-x-1.5 xl:space-x-3 items-center"
              >
                <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
                <span className="text-xs xl:text-base">Back</span>
              </button>

              <div className="w-full mt-5 xl:mt-0 space-y-10">
                <div className="flex justify-between items-center w-full ">
                  <div>
                    <p className="text-xl xl:text-2xl font-medium">Recipient details</p>
                    <p className="mt-1 xl:mt-2 font-medium text-xs xl:text-lg text-grey-text">
                      Please enter the details of the recipient
                    </p>
                  </div>
                  <Flag code={recipient_country.toUpperCase()} />
                </div>
                <DashboardCardLayout className="xl:w-full">
                  {fields &&
                    fields.map((field) =>
                      field.id !== 'tag_ids' ? (
                        <DynamicInput
                          id={field.id}
                          key={field.id}
                          label={field.label}
                          disabled={field.disabled}
                          hiddenState={field.hidden}
                          maxLength={field.limit}
                          placeholder={field.hint}
                          variant="secondary"
                          dynamicVariant={field.action === 'search' ? 'search' : 'input'}
                          field={field}
                        />
                      ) : (
                        <DynamicInput
                          id={field.id}
                          key={field.id}
                          label={field.label}
                          disabled={field.disabled}
                          hiddenState={field.hidden}
                          maxLength={field.limit}
                          placeholder={field.place_holder}
                          dynamicVariant="tag_ids"
                          field={field}
                        />
                      )
                    )}

                  <div className="w-full flex justify-between">
                    <p className="text-sm md:text-base">Save as beneficiary</p>
                    <CustomSwitch checked={saveToBeneficiary} onChange={handleChange} />
                  </div>
                </DashboardCardLayout>
                {/* <Input id="search" variant="secondary" /> */}
                <div></div>
              </div>
            </div>
          </div>
        </SubRouteLayout>
      )}
    </div>
  );
};

export default UserDetails;
