import { allCountries } from 'lib/countries/countries';
import { cn } from 'lib/utils';

const Flag = ({
  code,
  className,
  png,
  imgClass,
}: {
  code: string;
  className?: string;
  png?: boolean;
  imgClass?: string;
}) => {
  const country = allCountries.find((x) => x.cca2 === code);
  return (
    <div>
      {country && (
        <div className={cn('w-[2rem] h-[2rem] md:w-10 md:h-10 flex items-center', className)}>
          <img src={png ? country.flags.png : country.flags.svg} alt="code" className={imgClass} />
        </div>
      )}
    </div>
  );
};

export default Flag;
