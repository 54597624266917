import { useParams } from 'react-router-dom';

import ByBankTransfer from './bybanktransfer';

const BankAccount = () => {
  const { currency } = useParams();
  return <ByBankTransfer curr={currency} />;
};

export default BankAccount;
