import { CarouselContent, CarouselItem } from 'components/ui/carousel';
import { useCarousel } from 'hooks/useCarousel';
import { cn } from 'lib/utils';
import { useAppDispatch, useAppSelector } from 'store';
import { setPhysicalCardColor, setVirtualCardColor } from 'store/slices/cards/cardsSlice';
import {
  CARD_SLICE_REDUCER_PATH,
  PHYSICAL_CARD_COLOR_KEY,
  physicalCardList,
  VIRTUAL_CARD_COLOR_KEY,
  virtualCardList,
} from 'store/slices/cards/constants';
import 'swiper/css';
import 'swiper/css/pagination';

import EmptyCard from './emptyCard';

const ChooseCardColor = ({ type }: { type: 'physical' | 'virtual' }) => {
  const dispatch = useAppDispatch();
  const {
    [PHYSICAL_CARD_COLOR_KEY]: physicalCardColor,
    [VIRTUAL_CARD_COLOR_KEY]: virtualCardColor,
  } = useAppSelector((state) => state[CARD_SLICE_REDUCER_PATH]);
  const { api } = useCarousel();

  return (
    <div className="w-full xl:w-[37.5rem] ">
      {/* <Swiper
        modules={[Mousewheel, Pagination]}
        slidesPerView={1.2}
        mousewheel={{
          forceToAxis: true,
        }}
        pagination={{
          clickable: true,
          el: '.swiper-pagination',
          renderBullet: (index, className) => {
            const card = type === 'physical' ? physicalCardList[index] : virtualCardList[index]
            console.log(index)
            return `<span className="${className}" style={{background: card.class; padding: 10px;}}></span>`;
          }
        }}
        className="cardSwiper max-w-xl"
        breakpoints={{
          300: {
            slidesPerView: 1.05,
          },
          760: {
            slidesPerView: 1.2,
          },
        }}
      >
        {type === 'physical' &&
          physicalCardList.map((color, index) => (
            <SwiperSlide key={index}>
              <button
                className={cn(
                  'border-4 rounded-2xl p-1',
                  physicalCardColor === color ? 'border-platnova-purple' : 'border-transparent'
                )}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setPhysicalCardColor(color));
                }}
              >
                <EmptyCard type="physical" background={color.class} />
              </button>
            </SwiperSlide>
          ))}
        {type === 'virtual' &&
          virtualCardList.map((color, index) => (
            <SwiperSlide key={index}>
              <button
                className={cn(
                  'border-4 rounded-2xl p-1',
                  virtualCardColor === color ? 'border-platnova-purple' : 'border-transparent'
                )}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setVirtualCardColor(color));
                }}
              >
                <EmptyCard type="virtual" background={color.class} />
              </button>
            </SwiperSlide>
          ))}
      </Swiper>
      <div className="swiper-pagination" style={{ marginTop: '10px' }}></div> */}
      <CarouselContent>
        {type === 'physical' &&
          physicalCardList.map((color, index) => (
            <CarouselItem key={index} className="basis[90%] md:basis-[70%]" isCardCarousel={true}>
              <EmptyCard type={type} background={color.class} />
            </CarouselItem>
          ))}
        {type === 'virtual' &&
          virtualCardList.map((color, index) => (
            <CarouselItem key={index} className="basis[90%] md:basis-[70%]" isCardCarousel={true}>
              <EmptyCard type={type} background={color.class} />
            </CarouselItem>
          ))}
      </CarouselContent>
      <div className="flex items-center justify-center gap-3 mt-8">
        {type === 'physical' &&
          physicalCardList.map((color, index) => {
            physicalCardColor === color && api?.scrollTo(index);
            return (
              <button
                aria-label={`slide carousel to index ${index + 1}`}
                key={`selector-${index}`}
                type="button"
                className={cn([
                  'border-4 border-transparent scale-75 h-8 w-8 rounded-full',
                  physicalCardColor === color && 'border-white shadow-lg scale-110',
                ])}
                style={{ background: color.class }}
                onClick={() => {
                  api?.scrollTo(index);
                  dispatch(setPhysicalCardColor(color));
                }}
              />
            );
          })}
        {type === 'virtual' &&
          virtualCardList.map((color, index) => {
            virtualCardColor === color && api?.scrollTo(index);
            return (
              <button
                aria-label={`slide carousel to index ${index + 1}`}
                type="button"
                key={`selector-${index}`}
                className={cn([
                  'border-4 border-transparent scale-75 h-8 w-8 rounded-full',
                  virtualCardColor === color && 'border-gray-500 shadow-sm scale-110',
                ])}
                style={{ background: color.class }}
                onClick={() => {
                  api?.scrollTo(index);
                  dispatch(setVirtualCardColor(color));
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ChooseCardColor;
