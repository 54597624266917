import Button from 'components/Buttons/Button';
import useErrorNotification from 'components/error/useErrorNotification';
import Input from 'components/Inputs/input';
import Loader from 'components/Loader';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import { CREATE_BANK_ACCOUNT_ID_NUMBER_KEY } from 'lib/constants/accounts/deposit';
import { ChevronLeft, Copy } from 'lucide-react';
import { useEffect } from 'react';
import { BsFlagFill } from 'react-icons/bs';
import { GiCheckedShield } from 'react-icons/gi';
import { MdLightbulb, MdTimelapse } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import { ACCOUNTS_DEPOSIT_ROUTE } from 'routes/path';
import { useGetIssuedBankAccount } from 'services/account';
import { useAppDispatch } from 'store';
import { setPaymentMethod } from 'store/slices/account/accountsSlice';
import { copyToClipboard } from 'utils/clipboard';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';
import SubRouteLayout from 'views/dashboard/components/subRouteLayout';

import useCreateBankAccountForm from '../hooks/useCreateBankAccountForm';

interface ByBankTransferProps {
  curr: string | undefined;
}

const ByBankTransfer = ({ curr }: ByBankTransferProps) => {
  const { data } = useGetIssuedBankAccount();
  const dispatch = useAppDispatch();
  const {
    onSubmit,
    getInputProps,
    isPending,
    isError: isCreateBankAccountError,
    createAccountError,
  } = useCreateBankAccountForm();

  useEffect(() => {
    dispatch(setPaymentMethod(''));
  }, [dispatch]);

  const { currency } = useParams();

  const bankAccounts = data?.data.filter((x) => x.meta.currency === currency?.toUpperCase());

  useErrorNotification(isCreateBankAccountError, createAccountError);
  const getHeader = () => {
    // const fetchCurrencies = async () => {
    //   const fetchData = (await fetch('/configurations.json')).json();
    //   fetchData.then((data) => {
    //     console.log(data.data);
    //   });
    // };

    // fetchCurrencies();

    switch (curr) {
      case 'USD':
        return 'Bank Transfer';
      default:
        return 'BVN Verification';
    }
  };

  return bankAccounts && bankAccounts?.length > 0 && !isPending ? (
    <SubRouteLayout header={getHeader()}>
      {curr === 'USD' ? (
        <div className="pb-20 xl:-ml-[4.688rem] mx-[5%] xl:mx-0">
          <div className="xl:flex items-start xl:space-x-11 max-w-[43.75rem]">
            <Link
              to={ACCOUNTS_DEPOSIT_ROUTE}
              className="flex space-x-1.5 xl:space-x-3 items-center"
            >
              <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
              <span className="text-xs xl:text-base">Back</span>
            </Link>

            <div className="mt-5 xl:mt-0">
              <p className="text-lg xl:text-2xl font-medium">Account details</p>
              <p className="mt-2 text-grey-text text-xs xl:text-base">
                Receive money from other bank accounts using the details below
              </p>
              {bankAccounts.map((account, idx) => (
                <Accordion
                  key={idx}
                  type="single"
                  collapsible
                  className="bg-white shadow-sm p-5 rounded-[10px] mt-7 xl:mt-14 xl:w-[37.5rem] space-y-8"
                >
                  <AccordionItem value="item-1">
                    <AccordionTrigger>{account.bank_name}</AccordionTrigger>
                    <AccordionContent className="space-y-6 md:space-y-8">
                      <div className="flex flex-col gap-2 md:flex-row md:justify-between">
                        <p className="text-xs xl:text-base text-grey-text min-w-max">Beneficiary</p>
                        <div className="flex items-center justify-between space-x-3 md:max-w-[70%]">
                          <p className="text-xs xl:text-base font-medium truncate">
                            {account.account_name}
                          </p>
                          <button
                            onClick={() => copyToClipboard('Account name', account.account_name)}
                          >
                            <Copy size={15} className="text-platnova-purple" />
                          </button>
                        </div>
                      </div>

                      <div className="flex flex-col gap-2 md:flex-row md:justify-between">
                        <p className="text-xs xl:text-base text-grey-text">Account number</p>
                        <div className="flex items-center justify-between space-x-3 md:max-w-[70%]">
                          <p className="text-xs xl:text-base font-medium">
                            {account.account_number}
                          </p>
                          <button
                            onClick={() =>
                              copyToClipboard('Account number', account.account_number)
                            }
                          >
                            <Copy size={15} className="text-platnova-purple" />
                          </button>
                        </div>
                      </div>

                      <div className="flex flex-col gap-2 md:flex-row md:justify-between">
                        <p className="text-xs xl:text-base text-grey-text min-w-max">
                          ACH routing number
                        </p>
                        <div className="flex items-center justify-between space-x-3 md:max-w-[70%]">
                          <p className="text-xs xl:text-base font-medium truncate">
                            {account.meta.ach_routing_number}
                          </p>
                          <button
                            onClick={() =>
                              copyToClipboard('ACH routing number', account.meta.ach_routing_number)
                            }
                          >
                            <Copy size={15} className="text-platnova-purple" />
                          </button>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 md:flex-row md:justify-between">
                        <p className="text-xs xl:text-base text-grey-text min-w-max">
                          RTP routing number
                        </p>
                        <div className="flex items-center justify-between space-x-3 md:max-w-[70%]">
                          <p className="text-xs xl:text-base font-medium truncate">
                            {account.meta.rtp_routing_number}
                          </p>
                          <button
                            onClick={() =>
                              copyToClipboard('RTP routing number', account.meta.rtp_routing_number)
                            }
                          >
                            <Copy size={15} className="text-platnova-purple" />
                          </button>
                        </div>
                      </div>

                      <div className="flex flex-col gap-2 md:flex-row md:justify-between">
                        <p className="text-xs xl:text-base text-grey-text min-w-max">
                          Wire routing number
                        </p>
                        <div className="flex items-center justify-between space-x-3 md:max-w-[70%]">
                          <p className="text-xs xl:text-base font-medium truncate">
                            {account.meta.wire_routing_number}
                          </p>
                          <button
                            onClick={() =>
                              copyToClipboard(
                                'Wire routing number',
                                account.meta.wire_routing_number
                              )
                            }
                          >
                            <Copy size={15} className="text-platnova-purple" />
                          </button>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 md:flex-row md:justify-between">
                        <p className="text-xs xl:text-base text-grey-text">Account type</p>
                        <div className="flex items-center justify-between space-x-3 md:max-w-[70%]">
                          <p className="text-xs xl:text-base font-medium">
                            {account.meta.account_type}
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-col gap-2 md:flex-row md:justify-between">
                        <p className="text-xs xl:text-base text-grey-text">Bank name</p>
                        <div className="flex items-center justify-between space-x-3 md:max-w-[70%]">
                          <p className="text-xs xl:text-base font-medium truncate">
                            {account.bank_name}
                          </p>
                          <button onClick={() => copyToClipboard('Bank name', account.bank_name)}>
                            <Copy size={15} className="text-platnova-purple" />
                          </button>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 md:flex-row md:justify-between">
                        <p className="text-xs xl:text-base text-grey-text">Bank address</p>
                        <div className="flex items-center justify-between space-x-3 md:max-w-[70%]">
                          <p className="text-xs xl:text-base font-medium truncate">
                            {account.meta.bank_address}
                          </p>
                          <button
                            onClick={() =>
                              copyToClipboard('Bank address', account.meta.bank_address)
                            }
                          >
                            <Copy size={15} className="text-platnova-purple" />
                          </button>
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              ))}
              {/* <DashboardCardLayout className="mt-7 xl:mt-14 xl:w-[37.5rem] space-y-8">
                
                </DashboardCardLayout> */}

              <DashboardCardLayout className="mt-5 xl:w-[37.5rem] space-y-8">
                <div className="flex space-x-3 xl:space-x-11 items-center">
                  <GiCheckedShield className="stroke-grey-silent-icons fill-grey-silent-icons w-5 h-5" />
                  <p className="text-grey-text text-xs xl:text-base">
                    Your money is held in licensed banks.
                  </p>
                </div>

                <div className="flex space-x-3 xl:space-x-11 items-center">
                  <MdLightbulb className="stroke-grey-silent-icons fill-grey-silent-icons w-5 h-5" />
                  <p className="text-grey-text text-xs xl:text-base w-fit">
                    Use these details to receive transfers from only NGN bank accounts.
                  </p>
                </div>

                <div className="flex space-x-3 xl:space-x-11 items-center">
                  <MdTimelapse className="stroke-grey-silent-icons fill-grey-silent-icons w-5 h-5" />
                  <p className="text-grey-text text-xs xl:text-base">
                    Transfers are usually instant.
                  </p>
                </div>

                <div className="flex space-x-3 xl:space-x-11 items-center">
                  <BsFlagFill className="stroke-grey-silent-icons fill-grey-silent-icons w-5 h-5" />
                  <p className="text-grey-text text-xs xl:text-base w-fit">
                    Only local transfers are accepted. For international transfers, please contact
                    support
                  </p>
                </div>
              </DashboardCardLayout>
            </div>
          </div>
        </div>
      ) : (
        <div className="pb-20 xl:-ml-[4.688rem] mx-[5%] xl:mx-0">
          <div className="xl:flex items-start xl:space-x-11 max-w-[43.75rem]">
            <Link
              to={ACCOUNTS_DEPOSIT_ROUTE}
              className="flex space-x-1.5 xl:space-x-3 items-center"
            >
              <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
              <span className="text-xs xl:text-base">Back</span>
            </Link>

            <div className="mt-5 xl:mt-0">
              <DashboardCardLayout>
                <div className="space-y-3">
                  <p className="text-lg xl:text-2xl font-medium">Request NGN Account Details</p>
                  <p className="text-xs md:text-sm">
                    You can check your BVN from your 9mobile, Airtel or MTN line by dialing *565*0#
                    after 48 hours of enrolment.
                  </p>

                  <p className="text-xs md:text-sm">
                    Please note that your BVN doesn't give us access to any of your bank accounts.
                  </p>
                </div>
                <form className="mt-6 space-y-3 xl:space-y-6" onSubmit={onSubmit}>
                  <div>
                    <Input
                      label="BVN"
                      type="text"
                      placeholder="Enter your BVN"
                      id={CREATE_BANK_ACCOUNT_ID_NUMBER_KEY}
                      {...getInputProps(CREATE_BANK_ACCOUNT_ID_NUMBER_KEY)}
                    />
                    <span className="text-xs">
                      Please ensure that the name and date of birth you provided earlier match the
                      BVN details
                    </span>
                  </div>

                  <Input label="Bank Account Currency" value="NGN" id="currency" disabled />

                  <div className="space-y-6">
                    <p className="text-center">
                      No BVN?{' '}
                      <span className="text-platnova-purple font-medium">Get virtual account</span>
                    </p>

                    <Button variant="purple" isLoading={isPending} type="submit" className="w-full">
                      Submit
                    </Button>
                  </div>
                </form>
              </DashboardCardLayout>
            </div>
          </div>
        </div>
      )}
    </SubRouteLayout>
  ) : (
    <Loader className="mt-20" />
  );
};

export default ByBankTransfer;
