import { useFormik } from 'formik';
import {
  SEND_BANK_TRANSFER_ACCOUNT_NAME_KEY,
  SEND_BANK_TRANSFER_ACCOUNT_NUMBER_KEY,
  SEND_BANK_TRANSFER_BANK_CODE_KEY,
  SEND_BANK_TRANSFER_BANK_NAME_KEY,
  SEND_BANK_TRANSFER_BENEFICIARY_KEY,
  SEND_BANK_TRANSFER_BSB_KEY,
  SEND_CASH_APP_ID_KEY,
  SEND_DEFI_ADDRESS_NAME_KEY,
  SEND_DEFI_ASSET_NAME_KEY,
  SEND_DEFI_NETWORK_NAME_KEY,
  SEND_MOBILE_PHONE_NUMBER_KEY,
  SEND_PAYPAL_TRANSFER_KEY,
  SEND_ZELLE_ID_KEY,
} from 'lib/constants/accounts/send';

export default function useSendAssetForm() {
  const formik = useFormik({
    initialValues: {
      [SEND_DEFI_ASSET_NAME_KEY]: '',
      [SEND_DEFI_NETWORK_NAME_KEY]: '',
      [SEND_DEFI_ADDRESS_NAME_KEY]: '',
      [SEND_BANK_TRANSFER_BANK_NAME_KEY]: '',
      [SEND_BANK_TRANSFER_ACCOUNT_NUMBER_KEY]: '',
      [SEND_BANK_TRANSFER_ACCOUNT_NAME_KEY]: '',
      [SEND_BANK_TRANSFER_BENEFICIARY_KEY]: '',
      [SEND_BANK_TRANSFER_BSB_KEY]: '',
      [SEND_PAYPAL_TRANSFER_KEY]: '',
      [SEND_BANK_TRANSFER_BANK_CODE_KEY]: '',
      [SEND_MOBILE_PHONE_NUMBER_KEY]: '',
      [SEND_CASH_APP_ID_KEY]: '',
      [SEND_ZELLE_ID_KEY]: '',
    },
    onSubmit: () => {
      return;
    },
  });

  const errors = {
    [SEND_DEFI_ASSET_NAME_KEY]: 'Asset is required',
    [SEND_DEFI_NETWORK_NAME_KEY]: 'Network is required',
    [SEND_DEFI_ADDRESS_NAME_KEY]: 'Address cannot be empty',
    [SEND_BANK_TRANSFER_BANK_NAME_KEY]: 'Bank name is required',
    [SEND_BANK_TRANSFER_ACCOUNT_NUMBER_KEY]: 'Account number is required',
    [SEND_BANK_TRANSFER_ACCOUNT_NAME_KEY]: 'Account name is required',
    [SEND_BANK_TRANSFER_BENEFICIARY_KEY]: 'Beneficiary name is required',
    [SEND_BANK_TRANSFER_BSB_KEY]: 'BSB code is required',
    [SEND_PAYPAL_TRANSFER_KEY]: 'Please enter receiver email or username',
    [SEND_BANK_TRANSFER_BANK_CODE_KEY]: 'Please enter a valid network',
    [SEND_MOBILE_PHONE_NUMBER_KEY]: 'Phone number is required',
    [SEND_CASH_APP_ID_KEY]: 'ID or phone number is required',
    [SEND_ZELLE_ID_KEY]: 'Phone number or email is required',
  };

  function getInputProps(id: string) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
      ...formik.errors,
      setFieldTouched: formik.setFieldTouched,
    };
  }

  return Object.freeze({
    onSubmit: formik.handleSubmit,
    getInputProps,
    setValue: formik.setFieldValue,
    isValid: formik.isValid,
    errors,
    initialValues: formik.initialValues,
  });
}
