import Flag from 'components/flag';
import Loader from 'components/Loader';
import { ChevronLeft } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { SETTINGS_TRANSACTION_LIMITS_ACCOUNTS_ROUTE } from 'routes/path';
import { useGetWallets } from 'services/wallet';

import { ArrowDownIcon } from '../components/icons';
import SubRouteLayout from '../../components/subRouteLayout';

const flagStyle = 'h-[2rem] w-[2rem] md:h-full md:w-full';

const TransactionLimits = () => {
  const navigate = useNavigate();
  const { data, isPending } = useGetWallets();

  if (isPending) return <Loader />;
  return (
    <SubRouteLayout header="Transaction Limit">
      <div className="pb-20 w-full max-w-[52rem]">
        <div className="xl:flex items-start xl:space-x-11 w-full">
          <button
            onClick={() => navigate(-1)}
            className="flex space-x-1.5 xl:space-x-3 items-center"
          >
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </button>

          <div className="w-full mt-5 xl:mt-0">
            <div className="w-full">
              <div>
                <p className="text-xl xl:text-2xl font-medium">Transaction Limit</p>
                <p className="my-1 xl:mt-2 mb-4 xl:mb-8 font-medium text-xs xl:text-lg text-grey-text">
                  View the transaction limit for your various accounts
                </p>
                <div className="bg-white rounded-[0.625rem] p-5">
                  {data?.data.map(({ description, country, type }, id) => {
                    return (
                      <Link
                        to={SETTINGS_TRANSACTION_LIMITS_ACCOUNTS_ROUTE(type.toUpperCase())}
                        className="flex items-center justify-between p-[1rem_0_1rem_0]"
                        key={description + id}
                      >
                        <div className="flex gap-5">
                          <span className="rounded-full block overflow-hidden">
                            <Flag code={country.code} png imgClass={flagStyle} />
                          </span>
                          <div className="flex flex-col">
                            <p className="font-medium text-sm md:text-base">{description}</p>
                            <p className="text-[0.625rem] md:text-xs text-black/50">
                              {type.toUpperCase()}
                            </p>
                          </div>
                        </div>
                        <ArrowDownIcon width={32} height={32} />
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SubRouteLayout>
  );
};

export default TransactionLimits;
