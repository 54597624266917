import Loader from 'components/Loader';
import { TransactionLimitProps } from 'lib/constants/settings';
import { ToCamelCase } from 'lib/utils';
import { ChevronLeft } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import {
  SETTINGS_TRANSACTION_LIMITS_ACCOUNTS_ROUTE,
  SETTINGS_TRANSACTION_LIMITS_TYPE_ROUTE,
} from 'routes/path';
import {
  TransactionLimitsSortArray,
  useGetTransactionLimitsConfigurations,
} from 'services/configurations';
import { TransactionLimits } from 'types/configuration';

import { ArrowRightIcon } from '../components/icons';
import SubRouteLayout from '../../components/subRouteLayout';

const TransactionLimitsAcc = () => {
  const { data, isPending } = useGetTransactionLimitsConfigurations();

  const navigate = useNavigate();
  const foundMatch = useMatch(`${SETTINGS_TRANSACTION_LIMITS_ACCOUNTS_ROUTE(':acc')}`);
  const acc = foundMatch?.params.acc;

  const [limits, setLimits] = useState<Array<TransactionLimitProps>>();

  const LimitType = ({ name, href }: { name: string; href: string }) => {
    return (
      <Link
        to={SETTINGS_TRANSACTION_LIMITS_TYPE_ROUTE(acc as string, href)}
        className="flex items-center justify-between font-medium text-sm md:text-xl p-[1.25rem_0_1.25rem_0] border-b-[1px] border-[#0000001A]"
      >
        {name}
        <ArrowRightIcon width={32} height={32} />
      </Link>
    );
  };

  useEffect(() => {
    if (data) {
      const value = data?.data[0].value as Array<TransactionLimits>;
      const foundValue = value.filter((value) => value.currency === acc?.toLowerCase());
      setLimits(
        foundValue[0].types
          .sort(
            (a, b) =>
              TransactionLimitsSortArray.indexOf(a.id) - TransactionLimitsSortArray.indexOf(b.id)
          )
          .slice(0, 8) as Array<TransactionLimitProps>
      );
    }
  }, [acc, data]);

  if (isPending) return <Loader />;

  return (
    <SubRouteLayout header={`${acc} Transaction Limits`}>
      <div className="pb-20 w-full max-w-[52rem]">
        <div className="xl:flex items-start xl:space-x-11 w-full">
          <button
            onClick={() => navigate(-1)}
            className="flex space-x-1.5 xl:space-x-3 items-center"
          >
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </button>

          <div className="w-full mt-5 xl:mt-0">
            <div className="w-full">
              <div>
                <p className="text-xl xl:text-2xl font-medium">{`Transaction Limits of ${acc}`}</p>
                <p className="my-1 xl:mt-2 mb-4 xl:mb-8 font-medium text-xs xl:text-lg text-grey-text">
                  Select a transaction type to view the limits
                </p>
              </div>
            </div>
            {/* REST */}
            <div className="bg-white w-full rounded-[0.625rem] flex flex-col gap-5 p-5">
              {limits?.map(({ id }) => {
                return <LimitType key={id} name={ToCamelCase(id)} href={id} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </SubRouteLayout>
  );
};

export default TransactionLimitsAcc;
