import Loader from 'components/Loader';
import { TransactionLimitProps } from 'lib/constants/settings';
import { ToCamelCase, ToCurrStr } from 'lib/utils';
import { ChevronLeft } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { SETTINGS_TRANSACTION_LIMITS_TYPE_ROUTE } from 'routes/path';
import {
  TransactionLimitsSortArray,
  useGetTransactionLimitsConfigurations,
} from 'services/configurations';
import { useGetWallets } from 'services/wallet';
import { TransactionLimits } from 'types/configuration';

import SubRouteLayout from '../../components/subRouteLayout';

const LimitTypeCard = ({
  title,
  min,
  max,
  symbol,
}: {
  title: string;
  min: number;
  max: number;
  symbol: string | undefined;
}) => {
  return (
    <div className="bg-white flex flex-col shadow-[0px_6px_15px_1px_#6D6D6D26] rounded-[0.625rem] p-5 gap-4 [&>*:nth-child(n)]:p-[0.625rem_0_0.625rem_0]">
      <p className="text-sm md:text-lg font-medium">{title}</p>
      <p className="flex items-center justify-between text-sm md:text-base">
        Minimum
        <span className="font-medium"> {`${symbol}${ToCurrStr(min)}`}</span>
      </p>
      <p className="flex items-center justify-between text-sm md:text-base">
        Maximum
        <span className="font-medium"> {`${symbol}${ToCurrStr(max)}`}</span>
      </p>
    </div>
  );
};

const TransactionLimitsType = () => {
  const navigate = useNavigate();
  const { data } = useGetWallets();
  const { data: tLimits, isPending } = useGetTransactionLimitsConfigurations();
  const foundMatch = useMatch(`${SETTINGS_TRANSACTION_LIMITS_TYPE_ROUTE(':acc', ':limit')}`);
  const [limits, setLimits] = useState<Array<TransactionLimitProps>>();
  const acc = foundMatch?.params.acc;
  const limitType = foundMatch?.params.limit;
  const paymentMethods = limits?.filter((limit) => limit.id === limitType)[0].payment_methods;

  const symbol = data?.data.filter((data) => data.type.toLowerCase() === acc?.toLowerCase())[0]
    .symbol;

  const accAndType = `${acc} (${ToCamelCase(limitType as string)})`;

  useEffect(() => {
    if (tLimits) {
      const value = tLimits?.data[0].value as Array<TransactionLimits>;
      const foundValue = value.filter((value) => value.currency === acc?.toLowerCase());
      setLimits(
        foundValue[0].types
          .sort(
            (a, b) =>
              TransactionLimitsSortArray.indexOf(a.id) - TransactionLimitsSortArray.indexOf(b.id)
          )
          .slice(0, 8) as Array<TransactionLimitProps>
      );
    }
  }, [acc, tLimits]);

  if (isPending) return <Loader />;

  return (
    <SubRouteLayout header={`Limits of ${accAndType}`}>
      <div className="pb-20 w-full max-w-[52rem]">
        <div className="xl:flex items-start xl:space-x-11 w-full">
          <button
            onClick={() => navigate(-1)}
            className="flex space-x-1.5 xl:space-x-3 items-center"
          >
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </button>

          <div className="w-full mt-5 xl:mt-0">
            <div className="w-full">
              <div>
                <p className="text-xl xl:text-2xl font-medium">{`Limits of ${accAndType}`}</p>
                <p className="my-1 xl:mt-2 mb-4 xl:mb-8 font-medium text-xs xl:text-lg text-grey-text">
                  {`Per transaction limits for ${accAndType}`}
                </p>
              </div>
            </div>
            {/* REST */}
            <div className="w-full flex flex-col gap-5">
              {paymentMethods?.map(({ id, amount }, i) => {
                return (
                  <LimitTypeCard
                    key={id + i}
                    title={ToCamelCase(id)}
                    min={amount.min}
                    max={amount.max}
                    symbol={symbol}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </SubRouteLayout>
  );
};

export default TransactionLimitsType;
